<template>
  <div class="d-flex align-center">
    <v-btn
      v-if="showBtn"
      class="white--text"
      color="red"
      data-test="upgrade_workspace_category_button"
      elevation="0"
      @click="getUpgradeLink">
      My Plan
    </v-btn>
  </div>
</template>
<script>
import {
  mapGetters, mapState,
} from 'vuex';
export default {
  name: 'AppHeaderLayoutUpgradeBtn',
  props: {
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('Workspace', [
      'getActiveWorkspaceOwner',
      'isPersonalWorkspace',
    ]),
    ...mapState('Workspace', ['activeWorkspaceId']),
    showBtn() {
      return this.getActiveWorkspaceOwner?.id === this.userId && !this.isPersonalWorkspace;
    },
  },
  methods: {
    getUpgradeLink() {
      this.$router.push({
        name: 'workspace-payment',
        params: {
          wId: this.activeWorkspaceId,
        },
      });
    },
  },
};
</script>
